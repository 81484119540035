import React, { useState } from "react"
import Markdown from 'react-markdown'
import { Document, Page } from 'react-pdf';

import Layout from "../components/layout"

import year_2018 from '../images/2018.png'
import year_2019 from '../images/2019.png'
import year_2020 from '../images/2020.png'
import year_2021 from '../images/2021.png'
import year_2022 from '../images/2022.png'
import year_2023 from '../images/2023.png'

const yearImages = {
  '2018': year_2018,
  '2019': year_2019,
  '2020': year_2020,
  '2021': year_2021,
  '2022': year_2022,
  '2023': year_2023,
};

const ContentTemplate = ({ pageContext: { data, slug } }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const openModal = (id) => {
    setShowModal(true);
    setSelectedItemId(id);
  }

  const closeModal = () => {
    setShowModal(false);
    setSelectedItemId(null);
  }

  const nextItem = () => {
    const currentIndex = data.items.findIndex(item => item.id === selectedItemId);
    const nextIndex = (currentIndex + 1) % data.items.length;
    setSelectedItemId(data.items[nextIndex].id);
  }

  const prevItem = () => {
    const currentIndex = data.items.findIndex(item => item.id === selectedItemId);
    const prevIndex = (currentIndex - 1 + data.items.length) % data.items.length;
    setSelectedItemId(data.items[prevIndex].id);
  }

  const stopPropagation = (e) => {
    e.stopPropagation();
  }

  const itemsByYear = data.items.reduce((acc, item) => {
    const year = item.year
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});

  // Sort years in descending order
  const sortedYears = Object.keys(itemsByYear).sort((a, b) => a === 'null' ? 1 : b === 'null' ? -1 : b - a);

  return (
    <Layout title={slug}>
      {
        sortedYears.map(year => (
          <div key={year}>
            {year !== 'null' && <img src={yearImages[year]} alt={year} style={{ width: '20%' }}/>}
            <div className="image-grid">
              {itemsByYear[year].map((item, index) => (
                <div key={index} onClick={() => openModal(item.id)}>
                  <img src={item.image?.childImageSharp?.fluid?.src || item.image?.publicURL} />
                </div>
              ))}
            </div>
          </div>
        ))
      }

      {showModal && selectedItemId !== null && (
        <div className="modal-container" onClick={closeModal}>
          <div onClick={stopPropagation}>
            <button className="close-button" onClick={closeModal}>Close</button>
            <div className="carousel-container" style={slug === 'press' ? { maxHeight: 'unset' } : {}}>
              <button className="arrow" onClick={prevItem}>{'<'}</button>
              {
                data.items.find(item => item.id === selectedItemId) && 
                (slug === 'press' ? 
                <Document file={data.items.find(item => item.id === selectedItemId).pdf?.publicURL}>
                  <Page pageNumber={1} />
                </Document> :
                <img src={data.items.find(item => item.id === selectedItemId).image?.publicURL}/>
                )
              }
              <button className="arrow" onClick={nextItem}>{'>'}</button>
            </div>
            <div>
              {
                data.items.find(item => item.id === selectedItemId) && 
                  <pre style={{ fontWeight: 'normal' }}>
                    <Markdown>
                      {data.items.find(item => item.id === selectedItemId).description}
                    </Markdown>
                  </pre>
              }
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default ContentTemplate